const DesignMenuSide = () => import('@/views/my_users/MyDesign/DesignMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default [
    {
        path: "/design",
        redirect:"/design/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                name:'design_home',
                meta:{
                    title:"设计首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:DesignMenuSide,
                    default:() => import('@/views/my_users/MyDesign/DesignHome')
                }
            },
            {
                path:"aliexpress",
                meta:{
                    title:"主图",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"picture",
                        meta:{
                            title:"速卖通主图",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:DesignMenuSide,
                            default:() => import('@/views/my_users/MyDesign/AeImagePicture')
                        }
                    },
                    {
                        path:"sku",
                        meta:{
                            title:"速卖通SKU",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:DesignMenuSide,
                            default:() => import('@/views/my_users/MyDesign/AeImageSku')
                        }
                    },
                    {
                        path:"detail",
                        meta:{
                            title:"速卖通详情",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:DesignMenuSide,
                            default:() => import('@/views/my_users/MyDesign/AeImageDetails')
                        }
                    }
                ]
            },
            {
                path:"1688",
                meta:{
                    title:"主图",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"picture",
                        meta:{
                            title:"1688主图",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:DesignMenuSide,
                            default:() => import('@/views/my_users/MyDesign/AeImagePicture')
                        }
                    },
                    {
                        path:"sku",
                        meta:{
                            title:"1688SKU",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:DesignMenuSide,
                            default:() => import('@/views/my_users/MyDesign/AeImageSku')
                        }
                    },
                    {
                        path:"detail",
                        meta:{
                            title:"1688详情",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:DesignMenuSide,
                            default:() => import('@/views/my_users/MyDesign/AeImageDetails')
                        }
                    }
                ]
            },
        ]

    }]
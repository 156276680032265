const PromoMenuSide = () => import('@/views/my_users/MyPromo/PromoMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default  [
    {
        path:"/promo",
        redirect:"/promo/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                meta:{
                    title:"商品推广",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:PromoMenuSide,
                    default:() => import('@/views/my_users/MyPromo/PromoHome')
                },
            },
            {
                path:"item_dsr",
                meta:{
                    title:"单品DSR",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:PromoMenuSide,
                    default:() => import('@/views/my_users/MyPromo/ItemDSRCalculator')
                }
            },
            {
                path:"item_roi",
                meta:{
                    title:"ROI计算",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:PromoMenuSide,
                    default:() => import('@/views/my_users/MyPromo/ChartAnalyseROI')
                }
            },
            {
                path:"p4p",
                meta:{
                    title:"散点图",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"score_plan",
                        meta:{
                            title:"养分计划",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:PromoMenuSide,
                            default:() => import('@/views/my_users/MyPromo/P4PScorePlan')
                        }
                    },
                    {
                        path:"order_plan",
                        meta:{
                            title:"订单计划",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:PromoMenuSide,
                            default:() => import('@/views/my_users/MyPromo/P4POrderPlan')
                        }
                    },
                ]
            },
            {
                path:"alliance_marketing",
                meta:{
                    title:"联盟营销",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"store_list",
                        meta:{
                            title:"店铺列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:PromoMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreList')
                        }
                    },
                    {
                        path:"item_list",
                        meta:{
                            title:"商品列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:PromoMenuSide,
                            default:() => import('@/views/my_users/MyPromo/AllianceItemList')
                        }
                    },
                    {
                        path:"item_operate",
                        meta:{
                            title:"商品编辑/新建",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:PromoMenuSide,
                            default:() => import('@/views/my_users/MyPromo/AllianceItemOperate')
                        }
                    },
                    {
                        path:"task_list",
                        meta:{
                            title:"任务列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:PromoMenuSide,
                            default:() => import('@/views/my_users/MyPromo/AllianceTaskList')
                        }
                    },
                    {
                        path:"task_create",
                        meta:{
                            title:"任务创建",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:PromoMenuSide,
                            default:() => import('@/views/my_users/MyPromo/AllianceTaskOperate')
                        }
                    },
                ]
            }
        ],

    },
]

const TitleMenuSide = () => import('@/views/my_users/AeTitle/AeTitleMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default  [
    {
        path:"/attribute",
        redirect:'/attribute/analysis',
        component:UserHomeView,
        children:[
            {
                path:"demo",
                meta:{
                    title:"属性示例",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeAttribute/AttrDemo')
                }
            },
            {
                path:"record",
                meta:{
                    title:"属性记录",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeAttribute/AttrRecord')
                }
            },
            {
                path:"analysis",
                meta:{
                    title:"属性分析",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeAttribute/AttrHotSale')
                }
            },
            {
                path:"category",
                meta:{
                    title:"我的类目",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeStore/AeStoreCategory.vue')
                }
            },
            {
                path:"replace",
                meta:{
                    title:"属性替换规则",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeAttribute/AeAttrReplace.vue')
                }
            }
        ],

    },
]

const UserLogin = () => import('@/views/my_login/UserLogin.vue')
const UserRegister = () => import('@/views/my_login/UserRegister.vue')

const user_login = [
    {
        path: '/login',
        name: 'login',
        component: UserLogin
    },
    {
        path: '/register',
        name: 'register',
        component: UserRegister
    },
    {
        path: '/logout',
        name: 'logout',
        component: UserLogin
    },
]

export default user_login
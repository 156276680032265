const AdminUserMenuSide = () => import('@/views/my_admin/admin_user/AdminUserMenuSide')
const AdminTemplateView = () => import('@/views/AdminTemplateView')

export default [
      {
            path: "/web_admin",
            redirect:"/web_admin/home",
            component:AdminTemplateView,
            children:[
                  {
                        path:"home",
                        name:'web_admin',
                        meta:{
                              title:"后台首页",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        components:{
                              side_menu:AdminUserMenuSide,
                              default:() => import('@/views/my_admin/admin_user/AdminHome')
                        }
                  }
            ]
      }]
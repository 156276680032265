const web_home = [
    {
        path: '/home',
        name: 'homes',
        meta:{
            title:"首页",
            requiresAuth: true,
            keepAlive:true
        },
        component: () => import('@/views/HomeTemplateView'),
        children: [
            {
                path:"",
                name:"home_page",
                meta:{
                    title:"首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                component:() => import('@/views/my_home/WebInfo')

            },
            {
                // 匹配/logistics和/logistics/
                path:"regular_pricing",
                meta:{
                    title:"单品定价",
                    requiresAuth: true,
                    keepAlive:true
                },
                component:() => import('@/views/my_home/home_free_pricing_main')
            },
            {
                // 匹配/logistics和/logistics/
                path:"compare_pricing",
                meta:{
                    title:"跟卖定价",
                    requiresAuth: true,
                    keepAlive:true
                },
                component:() => import('@/views/my_home/FreePricingOld')
            },
            {
                // 匹配/logistics和/logistics/
                path:"free_video",
                meta:{
                    title:"跟卖定价",
                    requiresAuth: true,
                    keepAlive:true
                },
                component:() => import('@/views/my_home/home_free_video.vue')
            },
        ]
    },
]

export default web_home;

<template>
  <router-view/>
</template>

<style lang="scss">
@import "../static/css/my_app_css.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.box-card {
  width: 100%;
  margin-bottom: 10px;
  text-align:left ;
}

.text {
  font-size: 14px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


 //悬停单元格，高亮显示
.el-table tbody tr:hover>td {
  background-color:lightpink !important;
  color:white !important;
}

 //修改El message弹窗位置，避免影响操作
.el-message{
  margin-top:60px!important;
}

// 顶部菜单添加阴影
.el-menu--horizontal{
  //background-color: lightgray;
  box-shadow: 2px 2px 5px lightgray;
}
</style>

const SupplyMenuSide = () => import('@/views/my_users/MySupply/SupplyMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default  [{
        path: "/supply",
        redirect:"/supply/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                name:'supply_home',
                meta:{
                    title:"货源首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:SupplyMenuSide,
                    default:() => import('@/views/my_users/MySupply/SupplyHome')
                }
            },
            {
                path:"store",
                redirect:"/store/list",
                meta:{
                    title:"货源店铺",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"list",
                        meta:{
                            title:"店铺列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MySupply/SupplyStoreList')
                        }
                    }
                ]
            },
            {
                path:"item",
                redirect:"/item/list",
                meta:{
                    title:"货源商品",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"list",
                        meta:{
                            title:"商品列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MySupply/SupplyItemList')
                        }
                    },
                    {
                        path:"sku",
                        meta:{
                            title:"SKU列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MySupply/SupplyItemSKU')
                        }
                    },
                    {
                        path:"size",
                        meta:{
                            title:"尺码列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MySupply/SupplyItemSize')
                        }
                    },
                    {
                        path:"purchase",
                        meta:{
                            title:"货源列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MySupply/SupplyItemPurchase')
                        }
                    },
                    {
                        path:"competitor",
                        meta:{
                            title:"货源列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MySupply/SupplyItemCompetitorList')
                        }
                    },
                    {
                        path:"selection",
                        meta:{
                            title:"选品指南",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MySupply/SupplyItemSelection')
                        }
                    },
                ]
            },
        ]

    }]
const AdminUserMenuSide = () => import('@/views/my_admin/admin_user/AdminUserMenuSide')
const AdminTemplateView = () => import('@/views/AdminTemplateView')

export default [
      {
            path: "/web_admin",
            redirect:"/web_admin/home",
            component:AdminTemplateView,
            children:[
                  {
                        path:"users",
                        redirect:"/users/list",
                        meta:{
                              title:"用户",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        children:[
                              {
                                    path:"list",
                                    meta:{
                                          title:"用户列表",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminUserInfoList')
                                    }
                              },
                              {
                                    path:"edit",
                                    meta:{
                                          title:"用户编辑",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminUserInfoEdit')
                                    }
                              },
                              {
                                    path:"roles",
                                    meta:{
                                          title:"用户角色",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminUserRole')
                                    }
                              },
                        ]
                  },
                  {
                        path:"roles",
                        redirect:"/roles/list",
                        meta:{
                              title:"角色",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        children:[
                              {
                                    path:"list",
                                    meta:{
                                          title:"角色列表",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminRolesList')
                                    }
                              },
                              {
                                    path:"permissions",
                                    meta:{
                                          title:"角色权限",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminRolePermissions')
                                    }
                              },
                        ]
                  },
                  {
                        path:"permissions",
                        redirect:"/hot_item",
                        meta:{
                              title:"权限",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        children:[
                              {
                                    path:"list",
                                    meta:{
                                          title:"权限列表",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminPermissionsList')
                                    }
                              },
                        ]
                  },
            ]
      }]
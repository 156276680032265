const HotItemMenuSide = () => import('@/views/my_users/AeItem/HotItemMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default [
    {
        path: "/hot_item",
        redirect:"/hot_item/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                name:'hot_item_home',
                meta:{
                    title:"爆品首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:HotItemMenuSide,
                    default:() => import('@/views/my_users/AeItem/HotItemHome')
                }
            },
            {
                path:"info",
                redirect:"/hot_item/info/list",
                meta:{
                    title:"定价商品信息",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"list",
                        meta:{
                            title:"定价商品列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/HotItemList')
                        }
                    },
                    {
                        path:"create",
                        meta:{
                            title:"定价商品创建",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/HotItemCreate')
                        }
                    },{
                        path:"edit",
                        meta:{
                            title:"定价商品编辑",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/HotItemEdit')
                        }
                    },
                ]
            },
            {
                path:"pricing",
                redirect:"/hot_item",
                meta:{
                    title:"爆款定价",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"global_price",
                        meta:{
                            title:"零售定价",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/HotItemPriceGlobal')
                        }
                    },
                    {
                        path:"local_price",
                        meta:{
                            title:"区域定价",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/HotItemPriceLocal')
                        }
                    }
                ]
            },
            {
                path:"analysis",
                redirect:"/hot_item",
                meta:{
                    title:"爆款分析",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"by_data",
                        meta:{
                            title:"站内分析",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseItemOnline')
                        }
                    },
                    {
                        path:"by_excel",
                        meta:{
                            title:"表格分析",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseItemOffline')
                        }
                    },
                    {
                        path:"keyword",
                        meta:{
                            title:"关键词分析",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseKeyword')
                        }
                    },
                    {
                        path:"country",
                        meta:{
                            title:"国家分析",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseCountry')
                        }
                    },
                    {
                        path:"traffic_source",
                        meta:{
                            title:"流量来源",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseTrafficSource')
                        }
                    },
                    {
                        path:"scatter_plot",
                        meta:{
                            title:"散点图",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/ScatterPlot')
                        }
                    },
                ]
            },
            {
                path:"marketing",
                redirect:"/hot_item",
                meta:{
                    title:"爆款定价",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"list",
                        meta:{
                            title:"商品列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/HotItemHome')
                        }
                    },
                    {
                        path:"score",
                        meta:{
                            title:"评分计算",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/HotItemHome')
                        }
                    }
                ]
            },
            {
                path:"analyse_item",
                redirect:"/analyse_item/list",
                meta:{
                    title:"分析商品信息",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"list",
                        meta:{
                            title:"分析商品列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseItemList')
                        }
                    },                    {
                        path:"create",
                        meta:{
                            title:"分析商品创建",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseItemCreate')
                        }
                    }, {
                        path:"edit",
                        meta:{
                            title:"分析商品编辑",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeItem/AnalyseItemEdit')
                        }
                    }
                ]
            },
        ]

    }]
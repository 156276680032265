const IndustryMenuSide = () => import('@/views/my_users/MyIndustry/IndustryMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default  [{
    path: "/industry",
    redirect:"/industry/home",
    component:UserHomeView,
    children:[
        {
            path:"home",
            name:'industry_home',
            meta:{
                title:"行业首页",
                requiresAuth: true,
                keepAlive:true
            },
            components:{
                side_menu:IndustryMenuSide,
                default:() => import('@/views/my_users/MyIndustry/IndustryHome')
            }
        },
        {
            path:"list",
            meta:{
                title:"行业列表",
                requiresAuth: true,
                keepAlive:true
            },
            components:{
                side_menu:IndustryMenuSide,
                default:() => import('@/views/my_users/MyIndustry/IndustryList')
            },
        },
        {
            path:"analyse",
            redirect:"analyse/category",
            meta:{
                title:"行业分析",
                requiresAuth: true,
                keepAlive:true
            },
            children:[
                {
                    path:"category",
                    meta:{
                        title:"类目分析",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:IndustryMenuSide,
                        default:() => import('@/views/my_users/MyIndustry/AnalyseCategory')
                    }
                },
                {
                    path:"country",
                    meta:{
                        title:"国家分析",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:IndustryMenuSide,
                        default:() => import('@/views/my_users/MyIndustry/AnalyseCountry')
                    }
                },
                {
                    path:"price_scatter",
                    meta:{
                        title:"价格分析",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:IndustryMenuSide,
                        default:() => import('@/views/my_users/MyIndustry/AnalysePriceScatter')
                    }
                },
            ],
        },
    ]

}]
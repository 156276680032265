import { createStore } from 'vuex'
import {reactive} from "vue";

export default createStore({
  state: {
    web_base_url:["127.0.0.1", "localhost"].indexOf(window.location.hostname) > -1  ?"http://127.0.0.1:8090":window.location.origin,

    // 【el-upload】【headers参数添加token】为[请求头对象]添加token验证的token字段
    upload_headers_json:reactive({ token:localStorage.getItem('token'), email:localStorage.getItem('user_email') })
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

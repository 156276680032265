const AdminPromoMenuSide = () => import('@/views/my_admin/admin_promo/AdminPromoMenuSide')
const AdminTemplateView = () => import('@/views/AdminTemplateView')

export default [
      {
            path: "/web_admin/promo",
            redirect:"/web_admin/promo/home",
            component:AdminTemplateView,
            children:[
                  {
                        path:"home",
                        meta:{
                              title:"营销首页",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        components:{
                              side_menu:AdminPromoMenuSide,
                              default:() => import('@/views/my_admin/admin_promo/AdminPromoHome')
                        }
                  },
                  {
                        path:"alliance",
                        redirect:"/alliance/list",
                        meta:{
                              title:"联盟",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        children:[
                              {
                                    path:"list",
                                    meta:{
                                          title:"联盟任务列表",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminPromoMenuSide,
                                          default:() => import('@/views/my_admin/admin_promo/AllianceTaskListForManager')
                                    }
                              },
                        ]
                  },
            ]
      }]
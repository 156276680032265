import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'  // element plus
import zhCn from 'element-plus/es/locale/lang/zh-cn'  // element plus
import 'element-plus/dist/index.css'  // element plus

import * as ElementPlusIconsVue from '@element-plus/icons-vue'  // 图标
import VueClipboard from 'vue3-clipboard'   // 剪贴板

import router from './router'
import store from './store'

const app = createApp(App)

app.use(store)
app.use(router)

// 剪贴板
app.use(VueClipboard, {autoSetContainer: true, appendToBody: true})

// 在引入 Element Plus 时，可以传入一个包含 size 和 zIndex 属性的全局配置对象。
// size 用于设置表单组件的默认尺寸，zIndex 用于设置弹出组件的层级，zIndex 的默认值为 2000。
app.use(ElementPlus, { locale: zhCn, size: 'small', zIndex: 3000 })

// 使用图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) { app.component(key, component) }

app.mount('#app')

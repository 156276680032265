import { createRouter, createWebHistory} from 'vue-router'

import {get} from "@/api/all_request";
import {ElMessage} from "element-plus";

import admin_home from "@/router/my_admin/admin_home";
import admin_user from "@/router/my_admin/admin_user";
import admin_promo from "@/router/my_admin/admin_promo";
import admin_logistics from "@/router/my_admin/admin_logistics";

import web_home from "@/router/my_home/web_home";
import user_login from "@/router/my_login/user_login";

import ae_item_logistics from "@/router/my_users/ae_item_logistics";
import ae_item_pricing from "@/router/my_users/ae_item_pricing";
import ae_item_title from "@/router/my_users/ae_item_title";
import ae_item_attribute from "@/router/my_users/ae_item_attribute";

import my_industry from '@/router/my_users/my_industry'
import my_supply from "@/router/my_users/my_supply";

import ae_store from "@/router/my_users/ae_store";
import ae_hot_item from "@/router/my_users/ae_hot_item";
import my_competitor from "@/router/my_users/my_competitor";
import my_promo from "@/router/my_users/my_promo";
import my_design from "@/router/my_users/my_design";
import my_service from "@/router/my_users/my_service";

import web_user from "@/router/my_users/web_user";

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:"/home",
    component:  () => import('../views/HomeTemplateView')
  },
      ...admin_home,
      ...admin_user,
      ...admin_promo,
      ...admin_logistics,

    ...web_home,
    ...user_login,

    ...ae_item_logistics,
    ...ae_item_pricing,
    ...ae_item_title,
    ...ae_item_attribute,

    ...my_industry,
    ...my_supply,
    ...my_competitor,
    ...ae_hot_item,
    ...ae_store,
    ...my_promo,
    ...my_design,
    ...my_service,

    ...web_user,

]

const router = createRouter({
  base:'/dist',
  //设置为history模式
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  /*
    * to：即将要进入的目标路由对象；
    * from：当前导航即将要离开的路由对象；
    * next ：只有在调用该方法后，路由才会改变，才能进入下一个钩子函数（afterEach）。
  */
  if(["/login","/register", "/home", "/home/regular_pricing", "/home/free_video"].indexOf(to.fullPath) > -1){
    next()
  }else if(["/logout"].indexOf(to.fullPath) > -1){
    get('base_api/web_user/logout', {path:to.fullPath}).then(res => {
      if(res.data.code === 200){ next("/home")  }
    })

  }else{
    get('base_api/web_user/permission/check', {path:to.fullPath}).then(res => {
      /*
          * 117：登录超时
          * 118：用户不存在，没有登录权限
       */

      if(res.data.code === 200){
        next()
      }else if(res.data.code === 117){
        ElMessage.error(res.data.msg);next('/login');
      }else if(res.data.code === 119){
        ElMessage.error(res.data.msg);next('/');
      }else {
        ElMessage.error(res.data.msg);

        next(from.fullPath)
      }
    })
  }
})

router.afterEach((to) => {
  if (to.meta?.title) {
    // 设置页面`title`
    document.title = `${to.meta.title}`;
  }
})

export default router

const WebUserMenuSide = () => import('@/views/my_users/WebUser/WebUserMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default  [{
    path: "/member/",
    redirect:"/member/center",
    component:UserHomeView,
    children:[
        {
            path:"center",
            meta:{
                title:"用户中心",
                requiresAuth: true,
                keepAlive:true
            },
            components:{
                side_menu:WebUserMenuSide,
                default:() => import('@/views/my_users/WebUser/WebUserCenter')
            }
        },
        {
            path:"info",
            meta:{
                title:"用户信息",
                requiresAuth: true,
                keepAlive:true
            },
            children:[
                {
                    path:"data",
                    meta:{
                        title:"个人资料",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/WebUserInfoData')
                    }
                },
                {
                    path:"password",
                    meta:{
                        title:"修改密码",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/EditPassword')
                    }
                },
                {
                    path:"edit",
                    meta:{
                        title:"信息编辑",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/EditUserInfo')
                    }
                },
            ]
        },
        {
            path:"permissions",
            redirect:"/permissions/list",
            meta:{
                title:"会员权限",
                requiresAuth: true,
                keepAlive:true
            },
            children:[
                {
                    path:"list",
                    meta:{
                        title:"我的权限",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/WebUserPermissionsList')
                    }
                },
                {
                    path:"open",
                    meta:{
                        title:"开通权限",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/WebUserPermissionsOpen.vue')
                    }
                },
                {
                    path:"order",
                    meta:{
                        title:"用户订单",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/UserOrderList.vue')
                    }
                }
            ]
        },
        {
            path:"promote",
            redirect:"/promote/earns",
            meta:{
                title:"会员推广",
                requiresAuth: true,
                keepAlive:true
            },
            children:[
                {
                    path:"code",
                    meta:{
                        title:"推广代码",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/PromoteCodeCreate.vue')
                    }
                },
                {
                    path:"earns",
                    meta:{
                        title:"推广收益",
                        requiresAuth: true,
                        keepAlive:true
                    },
                    components:{
                        side_menu:WebUserMenuSide,
                        default:() => import('@/views/my_users/WebUser/PromoteEarnsList.vue')
                    }
                }
            ]
        },
    ]

}]

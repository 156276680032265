const SupplyMenuSide = () => import('@/views/my_users/MySupply/SupplyMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default  [
    {
        path:"/competitor",
        component:UserHomeView,
        children:[
            {
                path:"",
                meta:{
                    title:"竞品",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:SupplyMenuSide,
                    default:() => import('@/views/my_users/MyCompetitor/CompetitorHome')
                },
            },
            {
                path:"aliexpress",
                meta:{
                    title:"速卖通竞品",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"list",
                        meta:{
                            title:"AE竞品-列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MyCompetitor/AeCompetitorDetails')
                        }
                    },
                    {
                        path:"details",
                        meta:{
                            title:"AE竞品-详情",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MyCompetitor/AeCompetitorDetails')
                        }
                    },
                    {
                        path:"local_price",
                        meta:{
                            title:"AE竞品-区域价",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MyCompetitor/AeCompetitorLocalPrice')
                        }
                    },
                ]
            },
            {
                path:"amazon",
                meta:{
                    title:"亚马逊竞品",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"list",
                        meta:{
                            title:"AZ竞品列表",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:SupplyMenuSide,
                            default:() => import('@/views/my_users/MyCompetitor/AmazonCompetitorList')
                        }
                    },
                ]
            }
        ],

    },
]

const TitleMenuSide = () => import('@/views/my_users/AeTitle/AeTitleMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default  [
    {
        path: "/title",
        redirect:"/title/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                name:'title_home',
                meta:{
                    title:"标题首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeTitle/AeTitleHome')
                }
            },
            {
                path:"writing",
                meta:{
                    title:"标题撰写",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeTitle/AeTitleWriting')
                }
            },
            {
                path:"analysis",
                meta:{
                    title:"标题分析",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeTitle/AeTitleAnalyse')
                }
            },
            {
                path:"dedup",
                meta:{
                    title:"标题去重",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:TitleMenuSide,
                    default:() => import('@/views/my_users/AeTitle/AeTitleDedup')
                }
            },

        ]
    },
    ]

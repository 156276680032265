const ship_side_menu = () => import('@/views/my_users/AeLogistics/ShipMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default [{
        path: '/logistics',
        redirect:"/logistics/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                name:"logistics_home",
                meta:{
                    title:"运费首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:ship_side_menu,
                    default:() => import('@/views/my_users/AeLogistics/ShipHome')
                }
            },{
                path:"free_config",
                name:"free_config",
                redirect:"/logistics",
                meta:{
                    title:"包邮配置",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"self",
                        name:"free_config_self",
                        meta:{
                            title:"自定包邮配置",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipConfigCustom')
                        },
                    },
                ]
            },
            {
                path:"setting",
                redirect:"/logistics",
                meta:{
                    title:"运费设置",
                    requiresAuth: true,
                    keepAlive:true
                },
                children: [
                    {
                        path:"single",
                        meta:{
                            title:"运费设置 - 单个",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipSetSingle')
                        },
                    },
                    {
                        path:"bulk",
                        meta:{
                            title:"运费设置 - 批量",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipSetBulk')
                        },
                    }
                ]
            },
            {
                path:"chart",
                redirect:"/logistics",
                meta:{
                    title:"图表分析",
                    requiresAuth: true,
                    keepAlive:true
                },
                children: [
                    {
                        path:"by_route",
                        meta:{
                            title:"图表分析 - 按渠道",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipChartRoutes')
                        },
                    },
                    {
                        path:"by_country",
                        meta:{
                            title:"图表分析 - 按国家",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipChartCountry')
                        },
                    },
                    {
                        path:"free_analysis",
                        meta:{
                            title:"图表分析 - 包邮分析",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipChartFreeAnalyse')
                        },
                    }
                ]
            },
            {
                path:"table",
                redirect:"/logistics",
                meta:{
                    title:"表格分析",
                    requiresAuth: true,
                    keepAlive:true
                },
                children: [
                    {
                        path:"multi_routes",
                        meta:{
                            title:"表格对比 - 多渠道",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipCompareRoutes')
                        },
                    },
                    {
                        path:"by_country",
                        meta:{
                            title:"表格对比 - 按国家",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components: {
                            side_menu:ship_side_menu,
                            default:()=>import('@/views/my_users/AeLogistics/ShipCompareCountry')
                        },
                    }
                ]
            },
        ]
    }]
const HotItemMenuSide = () => import('@/views/my_users/AeStore/AeStoreMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default [
    {
        path: "/smt_store",
        redirect:"/smt_store/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                name:'smt_store_home',
                meta:{
                    title:"店铺首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:HotItemMenuSide,
                    default:() => import('@/views/my_users/AeItem/HotItemHome')
                }
            },
            {
                path:"list",
                meta:{
                    title:"店铺列表",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:HotItemMenuSide,
                    default:() => import('@/views/my_users/AeStore/AeStoreList')
                }
            },
            {
                path:"traffic_analyse",
                redirect:"/smt_store/traffic_analyse/key",
                children:[
                    {
                        path:"key",
                        meta:{
                            title:"流量分析-核心指标",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreTrafficAnalyseKey')
                        }
                    },
                    {
                        path:"country",
                        meta:{
                            title:"流量分析-国家",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreTrafficAnalyseCountry')
                        }
                    },
                ]
            },
            {
                path:"selection",
                redirect:"/smt_store/selection/by_transaction_index",
                children:[
                    {
                        path:"by_transaction_index",
                        meta:{
                            title:"店内选品 - 成交指数",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreSelectionByTransaction')
                        }
                    },
                    {
                        path:"by_potential_index",
                        meta:{
                            title:"店内选品 - 潜力指数",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreSelectionByPotential')
                        }
                    },
                ]
            },
            {
                path:"profit",
                redirect:"/smt_store/profit/calculate",
                children:[
                    {
                        path:"calculate",
                        meta:{
                            title:"店铺盈利核算",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreProfitCalculate')
                        }
                    },
                ]
            },
            {
                path:"item_code",
                redirect:"/smt_store/item_code/info",
                children:[
                    {
                        path:"info",
                        meta:{
                            title:"店铺商品货号",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreItemCode')
                        }
                    },
                ]
            },
            {
                path:"category",
                // redirect:"/smt_store/category",
                children:[
                    {
                        path:"",
                        meta:{
                            title:"店铺类目",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:HotItemMenuSide,
                            default:() => import('@/views/my_users/AeStore/AeStoreCategory')
                        }
                    },
                ]
            },
        ]

    }]

const AdminUserMenuSide = () => import('@/views/my_admin/admin_user/AdminUserMenuSide')
const AdminTemplateView = () => import('@/views/AdminTemplateView')

export default [
      {
            path: "/web_admin/logistics",
            component:AdminTemplateView,
            children:[
                  {
                        path:"ship_cost",
                        meta:{
                              title:"后台首页",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        components:{
                              side_menu:AdminUserMenuSide,
                              default:() => import('@/views/my_admin/admin_logistics/AdminShipCostList')
                        }
                  },
                  {
                        path:"country",
                        redirect:"/users/list",
                        meta:{
                              title:"用户",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        children:[
                              {
                                    path:"list",
                                    meta:{
                                          title:"用户列表",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminUserInfoList')
                                    }
                              },
                        ]
                  },
                  {
                        path:"routes",
                        redirect:"/roles/list",
                        meta:{
                              title:"爆款定价",
                              requiresAuth: true,
                              keepAlive:true
                        },
                        children:[
                              {
                                    path:"list",
                                    meta:{
                                          title:"角色列表",
                                          requiresAuth: true,
                                          keepAlive:true
                                    },
                                    components:{
                                          side_menu:AdminUserMenuSide,
                                          default:() => import('@/views/my_admin/admin_user/AdminRolesList')
                                    }
                              },
                        ]
                  },
            ]
      }]
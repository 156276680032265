const ServiceMenuSide = () => import('@/views/my_users/MyService/ServiceMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default [
    {
        path: "/service",
        component:UserHomeView,
        children:[
            {
                path:"",
                name:'service_home',
                meta:{
                    title:"服务首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:ServiceMenuSide,
                    default:() => import('@/views/my_users/MyService/ServiceHome')
                }
            },
            {
                path:"permissions",
                meta:{
                    title:"权限开通",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"aliexpress",
                        meta:{
                            title:"店铺首页",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:ServiceMenuSide,
                            default:() => import('@/views/my_users/MyService/ServiceHome')
                        }
                    },
                    {
                        path:"1688",
                        meta:{
                            title:"店铺首页",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:ServiceMenuSide,
                            default:() => import('@/views/my_users/MyService/ServiceHome')
                        }
                    }
                ]
            },
        ]

    }]
import axios from "axios";
import {ElMessage} from "element-plus";
import store from "@/store";

// https://blog.csdn.net/weixin_52797441/article/details/126979875

const  adm_inInstance = axios.create({
    baseURL:store.state.web_base_url,
    timeout:30000,
})

// 请求拦截器
adm_inInstance.interceptors.request.use(config=>{
        /*
             *  每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
             * 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
         */
        // 【添加token】为请求头对象，添加token验证的token字段
        config.headers.token = localStorage.getItem('token')
        config.headers.email = localStorage.getItem('user_email')

        // 【放行】不写此句就被拦截在这里无法继续传递
        return config
    },
    error => {
        // 放行，让下级处理，被catch捕捉
        return Promise.reject(error)
    }
)

// 响应拦截器
adm_inInstance.interceptors.response.use(
    /*
       * onFulfilled:名称自定义，常见response，此处写myResponse
       * onRejected:名称自定义，常见error
     */

    // 在2xx范围内的任何状态代码都会触发此函数，这里主要用于处理响应数据
    myResponse=>{

        if (myResponse.status === 200) {
            // 【进行中】放行，不写此句就被拦截在这里无法继续传递
            return Promise.resolve(myResponse);
        } else {
            ElMessage.error("服务器返回错误，请联系管理员！")
            // 【失败】放行，不写此句就被拦截在这里无法继续传递
            return Promise.reject(myResponse);
        }
    },
    // 任何超出2xx范围的状态码都会触发此函数，这里主要用于处理响应错误
    error => {
        const { status } = error.response

        if (status === 400) {// 客户端请求参数错误
            ElMessage.error('客户端请求参数错误')
        }else if (status === 401) { // 未授权
            ElMessage.error('未授权')
        } else if (status === 403) { // 没有操作权限
            ElMessage.error('403，没有操作权限！')
        } else if (status === 404) { // 资源不存在(服务器没有请求的链接）
            ElMessage.error('404，资源不存在！')
        } else if (status >= 500) { // 服务端异常
            ElMessage.error('500，服务器错误！')
        }

        // 放行，让下级处理，被catch捕捉
        return Promise.reject(error)
    }
)


export function get(url, params){
    return adm_inInstance.get(url, {params})
}

export function formPost(url, params){
      return adm_inInstance.post(url, params,{
                  headers:{
                        // 以表单形式传递
                        'Content-Type': 'multipart/form-data'
                  }
            }
      )
}

export function post(url, params){
    return adm_inInstance.post(url, params,{
            transformRequest:{
                function(data){
                    let paramStr="";
                    for(let key in data){
                        if(paramStr.length===0){
                            paramStr = encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
                        }else{
                            paramStr += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
                        }
                    }
                    return paramStr
                }
            },
            headers:{
                // 以表单形式传递
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
    )
}


export function my_delete(url, req_params){
    return adm_inInstance.delete(url, {params:req_params})
}
const pricing_side_menu = () => import('@/views/my_users/AePricing/PriceMenuSide')
const UserHomeView = () => import('@/views/UsersTemplateView')

export default [{
        path: "/pricing",
        redirect: "/pricing/home",
        component:UserHomeView,
        children:[
            {
                path:"home",
                name:'price_home',
                meta:{
                    title:"定价首页",
                    requiresAuth: true,
                    keepAlive:true
                },
                components:{
                    side_menu:pricing_side_menu,
                    default:() => import('@/views/my_users/AePricing/PriceHome')
                }
            },
            {
                path:"config",
                redirect:"/pricing",
                meta:{
                    title:"定价配置",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"global",
                        name:"pricing_config_global",
                        meta:{
                            title:"常规定价配置",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PriceConfigGlobal')
                        }
                    },
                    {
                        path:"local",
                        name:"pricing_config_local",
                        meta:{
                            title:"区域定价配置",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PriceConfigLocal')
                        }
                    }
                ]
            },
            {
                path:"unit_pricer",
                name:'unit_pricer',
                meta:{
                    title:"单品定价器",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"by_profit",
                        name:"by_profit",
                        meta:{
                            title:"单定器 - 按利润",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PricerGlobalByProfitRate')
                        }
                    },
                    {
                        path:"by_profit_rate",
                        name:"by_profit_rate",
                        meta:{
                            title:"单定器 - 按利率",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PricerGlobalByProfitRate')
                        }
                    },
                    {
                        path:"by_ori_price",
                        name:"by_ori_price",
                        meta:{
                            title:"单定器 - 按原价",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PricerGlobalByOriPrice')
                        }
                    },
                    {
                        path:"multi_sku",
                        name:"multi_sku",
                        meta:{
                            title:"单定器 - 多SKU",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PricerGlobalMultiSKU')
                        }
                    },
                ]
            },
            {
                path:"zone_pricer",
                name:'zone_pricer',
                redirect:"/pricing",
                meta:{
                    title:"区域定价器",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"local",
                        name:"",
                        meta:{
                            title:"区定器 - 按利润",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PricerLocal')
                        }
                    },
                ]
            },
            {
                path:"theory",
                name:'theory',
                redirect:"/pricing",
                meta:{
                    title:"定价核心理论",
                    requiresAuth: true,
                    keepAlive:true
                },
                children:[
                    {
                        path:"core",
                        name:"core_theory",
                        meta:{
                            title:"核心理论",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PriceHome')
                        }
                    },
                    {
                        path:"normal_price",
                        name:"normal_price",
                        meta:{
                            title:"定价推导式",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PriceHome')
                        }
                    },
                    {
                        path:"hidden_price",
                        name:"hidden_price",
                        meta:{
                            title:"藏价推导式",
                            requiresAuth: true,
                            keepAlive:true
                        },
                        components:{
                            side_menu:pricing_side_menu,
                            default:() => import('@/views/my_users/AePricing/PriceHome')
                        }
                    },
                ]
            }
        ]

    }]